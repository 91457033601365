* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  color: #333;
}

.tic-tac-toe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.256);
}

.row {
  display: flex;
}

.square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  margin: 3px;
  background: #ededed84;
  border: 2px solid #000000;
  border-radius: 10px;
  font-size: 40px;
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
  transition: background 0.4s, color 0.4s, transform 0.4s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.244);
}

.square:hover {
  background: #007bff;
  color: #ffffff;
  transform: scale(1.07);
}

.square:active {
  background: #0056b3;
  color: red;
  transform: scale(0.8); /* Slightly smaller when clicked */
}

.restart-button {
  background-color: #54869f; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 15px 32px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the button block-level */
  font-size: 16px; /* Increase font size */
  margin-top: 20px;
  cursor: pointer; /* Pointer/hand icon */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
  border-radius: 8px; /* Rounded corners */
}

.restart-button:hover {
  background-color: #3c00ff; /* Darker green background on hover */
  transform: scale(1.15); /* Slightly larger on hover */
}

.restart-button:active {
  transform: scale(0.7); /* Slightly smaller when clicked */
}

.restart-button:focus {
  outline: none; /* Remove default outline on focus */
  box-shadow: 5px 5px #454545; /* Green shadow on focus */
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .square {
    width: 100px;
    height: 100px;
    font-size: 30px;
    margin: 2px;
  }

  .restart-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .square {
    width: 80px;
    height: 80px;
    font-size: 24px;
    margin: 1px;
  }

  .restart-button {
    padding: 8px 16px;
    font-size: 12px;
  }

  .tic-tac-toe-container {
    padding: 10px;
  }
}
  